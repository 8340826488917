var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DarkWhite"},[_c('v-card',{staticClass:"mb-5",attrs:{"loading":_vm.filterLoading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"header","indeterminate":""}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"item-text":"FundCode","item-value":"FundID","items":_vm.funds,"hide-details":"","color":"header","label":"Filter by Funds"},model:{value:(_vm.FundID),callback:function ($$v) {_vm.FundID=$$v},expression:"FundID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"item-text":"ClassificationDescription","item-value":"ClassificationID","items":_vm.classifications,"hide-details":"","color":"header","label":"Filter by Classification"},model:{value:(_vm.ClassificationID),callback:function ($$v) {_vm.ClassificationID=$$v},expression:"ClassificationID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"dense":"","close-on-content-click":false,"return-value":_vm.DateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.DateRange=$event},"update:return-value":function($event){_vm.DateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"dense":"","label":"Date Range","color":"header","hide-details":"","clearable":""},model:{value:(_vm.DateRange),callback:function ($$v) {_vm.DateRange=$$v},expression:"DateRange"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","color":"header"},model:{value:(_vm.DateRange),callback:function ($$v) {_vm.DateRange=$$v},expression:"DateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"header"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"header"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.DateRange)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"UserID","items":_vm.users,"hide-details":"","color":"header","label":"Filter by User"},model:{value:(_vm.UserID),callback:function ($$v) {_vm.UserID=$$v},expression:"UserID"}})],1)],1)],1)],2),_c('v-data-table',{staticClass:"elevation-1 pt-2",attrs:{"headers":_vm.headers,"items":_vm.itemList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-2 mt-2",attrs:{"append-icon":"mdi-magnify","placeholder":"Search Item here...","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('vue-json-to-csv',{attrs:{"json-data":_vm.itemListForCSV,"labels":{ 
          StockPropertyNo: { title: 'Stock No' },
          ItemName: { title: 'ItemName' },
          ItemDescription: { title: 'ItemDescription' },
          Qty: { title: 'Qty' },
          UnitCost: { title: 'UnitCost' },
          Amount: { title: 'Amount' },
          Code: { title: 'ICS/PAR Code' },
          DateCreated: { title: 'Date Issued' },
          status_text: { title: 'Status' },
          },"csv-title":"Inventory"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"green darken-2","dark":"","loading":_vm.loadingCSV}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" CSV ")],1)],1)],1)]},proxy:true},{key:"item.Num",fn:function(ref){
          var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.options.itemsPerPage * (_vm.options.page-1)) + index + 1)+" ")]}},{key:"item.ItemName",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.ItemName)+" ")]}},{key:"item.Qty",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQty(item.Qty))+" ")]}},{key:"item.UnitCost",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.UnitCost)))])]}},{key:"item.Amount",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.Amount)))])]}},{key:"item.Code",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Code))])]}},{key:"item.DateAcquired",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateAcquired))+" ")]}},{key:"item.DateCreated",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.Status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.Status))+" ")]}},{key:"item.Remarks",fn:function(ref){
          var item = ref.item;
return [(item.RenewItemID)?_c('v-chip',{staticClass:"white--text mr-3",attrs:{"color":"header darken-2","small":""}},[_vm._v(" Renewed ")]):(item.TransferItemID)?_c('v-chip',{staticClass:"white--text mr-3",attrs:{"color":"amber darken-2","small":""}},[_vm._v(" Transferred ")]):(item.ReturnItemID)?_c('v-chip',{staticClass:"white--text mr-3",attrs:{"color":"blue darken-2","small":""}},[_vm._v(" Returned ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.view(item)}}},[_vm._v(" View ")])],1)]}}],null,true)}),_c('ICSModal',{attrs:{"icsData":_vm.icsData}}),_c('PARModal',{attrs:{"parData":_vm.parData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }